import * as React from "react"
import { Section, Button, Card, Container } from "@bw/bits"
import { Hero, Columns } from "@bw/modules"
import { Layout } from "@bw/layouts"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { breakpoints } from "../theme"
import heroImage from "../images/hero.jpg"

const Offset = styled.div`
  margin-top: -240px;

  @media (min-width: ${breakpoints.medium}px) {
    margin-bottom: 200px;
  }
`

const Direction = styled.div`
  @media (min-width: ${breakpoints.medium}px) {
    transform: translateY(120px);
  }
`

const IndexPage = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <Hero
        suptitle="Votre"
        title="Épicerie Bio"
        excerpt={<>Votre épicerie bio, vrac, local à Gland!<br />Venez me rencontrer et découvrir mon assortiment.</>}
        background="var(--primary)"
        contentSize="490px"
        backgroundImage={heroImage}
      />
      <Offset>
        <Section pattern>
          <Columns count={2}>
            <Card type="text" direction="left" pattern="green">
              <h3>Chez l'Epicier - Yann Bechtel</h3>
              <address>
                Rue du Borgeaud 9<br />
                1196 Gland
              </address>
              <a
                href="https://maps.app.goo.gl/pucGZBtcvytGX85NA"
                target="_blank"
                rel="noreferrer"
              >
                Itinéraire
              </a>
              <div style={{ marginTop: '.5em' }}>
                Tél: <a href="tel:+41786154575">078 615 45 75</a><br />
                Courriel: <a href="mailto:contact@chezlepicier.ch">contact@chezlepicier.ch</a>
              </div>
              <h4>Horaires d'ouverture</h4>
              Lu <b>Fermé</b>
              <br /> Ma-Ve <b>10h-18h45</b>
              <br /> Sa <b>10h-18h</b>
              <br /> Di <b>Fermé</b>
            </Card>
            <Direction>
              <h3>Nous trouver</h3>
              <b>En voiture </b>
              <p>
                Autoroute A1 sortie Gland-Begnins, direction Gland. A gauche au
                premier giratoire. A votre gauche juste avant le premier
                carrefour et vous y êtes ! Places de parc devant le magasin,
                places bleues et parking souterrain à proximité.
              </p>

              <b>En transports publics </b>
              <p>
                Depuis la gare CFF de Gland, à 10 minutes à pieds et à 3 minutes
                à vélo (850m) Prendre direction nord, par la rue de la Gare.
                Prenez à gauche sur la Grand'rue, puis à droite sur la rue du
                Jura. Enfin, à gauche sur la rue du Borgeaud et vous y êtes !
              </p>
              <Button
                target="_blank"
                href="https://www.google.com/maps/dir/?api=1&destination=Rue+du+Borgeaud+9,+1196+Gland"
                label="Itinéraire"
              />
            </Direction>
          </Columns>
        </Section>
      </Offset>
    </Layout>
  )
}

export default IndexPage
