import * as React from "react"
import { Section, Button, Card } from "@bw/bits"
import { Hero, Columns, Content } from "@bw/modules"
import { Layout } from "@bw/layouts"
import { useTranslation } from "react-i18next"
import data from "../partenaires.json"

const ListPartenaires = data
  .map(group =>
    <Section padding="45px 0" pattern key={group.group} title={group.group}>
      <div style={{ marginTop: '2em' }} />
      <Columns count={2}>
        {
          group.partners.map((partenaire, index) =>
            <Card type="text" direction="left" key={index}>
              <p>
                <b>{partenaire.name}</b><br />
                {partenaire.city}
              </p>
              <p>
                <strong>Dans notre assortiment:</strong>
                <br />
                {partenaire.assortment}
              </p>
            </Card>)
        }
      </Columns>
    </Section>
  )


const IndexPage = () => {
  const { t } = useTranslation()
  const partenaireAnchorRef = React.useRef(null)
  return (
    <Layout>
      <Hero
        suptitle={t("Liste")}
        title={t("des partenaires")}
        excerpt="Nous travaillons directement avec les producteurs et transformateurs suivants"
        background="var(--primary)"
        button={
          <Button
            primary
            label={t("Devenir partenaire")}
            inversed
            to="#partenaire"
          />
        }
        contentSize="490px"
      />
      {ListPartenaires}
      <Section padding="45px 0" pattern>
        <Content>
          <p>
            Pour le reste, nous nous fournissons chez les grossistes suivants
          </p>
          <ul>
            <li>Coopérative Biofarm</li>
            <li>BioPartner</li>
            <li>Sun-Snack</li>
            <li>Montasell SA</li>
            <li>Ma ligne Bien-Etre</li>
            <li>AromaCos</li>
          </ul>
        </Content>
      </Section>


      <Section
        id="partenaire"
        suptitle="Devenir"
        title="partenaire"
        padding="96px 0 44px"
        background="var(--grey)"
        decoration
        ref={partenaireAnchorRef}
      >
        <Columns count={2}>
          <Content>
            <p>Chez l’Epicier, je mise sur le partenariat !</p>
            <p>
              Vous êtes un producteur, un artisan, une association ou encore un prestataire de services et vous vous intégrez dans les valeurs de Chez l'Epicier?
            </p>
          </Content>
          <Content>
            <p>
              Je suis à votre disposition et vous répondrai volontiers par téléphone ou par e-mail. Plus simple encore, passez me voir !
            </p>
            <p>
              Téléphone: <a href="tel:+41786154575">078 615 45 75</a>
              <br />
              Email:{" "}
              <a href="mailto:contact@chezlepicier.ch">
                contact@chezlepicier.ch
              </a>
            </p>
          </Content>
        </Columns>
      </Section>
    </Layout >
  )
}

export default IndexPage
